<template>
  <b-card>
    <h2>
      <b-link to="/teachers/add" class="btn btn-primary">
        <feather-icon icon="UserPlusIcon" size="20" />
        إضافة معلم جديد
      </b-link>
      &nbsp;
      <b-link class="btn btn-info" to="/teachers/import">
        <feather-icon icon="DownloadIcon" size="20" />
        استيراد المعلمين
      </b-link>
      &nbsp;
      <button class="btn btn-secondary" @click="barcode()">
        طباعة بطاقات المعلمين (الباركود)
      </button>
      <img
        src="../../assets/images/loading.svg"
        style="width: 60px; height: 60px"
        alt=""
        v-if="loading"
      />
    </h2>
    <hr />
    <div class="col-12 table-responsive">
      <table class="table table-hover table-bordered table-striped">
        <thead>
          <th>المعلم</th>
          <th>رقم الهوية</th>
          <th>مجموعة الإعدادات</th>
          <th>خيارات</th>
        </thead>
        <tbody>
          <tr v-for="teacher in teachers" v-bind:key="teacher._id">
            <td>
              {{ teacher.name }}
              <span v-if="teacher.finger">
                <img
                  :src="require('@/assets/images/finger.png')"
                  style="width: 20px; height: 20px"
                  title="✔ لديه بصمة"
                />
              </span>
            </td>
            <td>
              {{ teacher.number }}
            </td>
            <td>
              {{ teacher.group_title }}
            </td>
            <td>
              <!-- primary -->
              <b-dropdown text="خيارات" variant="primary">
                <b-dropdown-item
                  @click="go('/teachers/overview/' + teacher._id)"
                >
                  <feather-icon icon="MapIcon" size="20" />
                  نظرة عامة
                </b-dropdown-item>
                <b-dropdown-item @click="go('/teachers/edit/' + teacher._id)">
                  <feather-icon icon="EditIcon" size="20" />
                  تعديل المعلم
                </b-dropdown-item>
                <b-dropdown-item @click="deleteTeacher(teacher._id)">
                  <feather-icon icon="TrashIcon" size="20" />
                  حذف المعلم
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BLink,
  },
  methods: {
    deleteTeacher(id) {
      if (confirm("متأكد من حذف المعلم؟")) {
        var _g = this;
        axios
          .post(localStorage.getItem("api") + "/teachers/delete", {
            jwt: localStorage.getItem("jwt"),
            id: id,
          })
          .then(function (r) {
            if (r.data.status == 200) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ JWT",
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم الحذف بنجاح",
                  icon: "WarningIcon",
                  variant: "success",
                },
              });
              axios
                .post(localStorage.getItem("api") + "/teachers/list", {
                  jwt: localStorage.getItem("jwt"),
                })
                .then(function (r) {
                  if (r.data.status == 200) {
                    _g.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "حدث خطأ JWT",
                        icon: "WarningIcon",
                        variant: "danger",
                      },
                    });
                  } else {
                    _g.teachers = r.data.response;
                    _g.loading = false;
                  }
                })
                .catch(function () {
                  _g.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "حدث خطأ",
                      icon: "WarningIcon",
                      variant: "danger",
                    },
                  });
                });
            }
          })
          .catch(function () {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    go(url) {
      this.$router.push(url);
    },
    settingsGroupTitle(code) {
      this.groups.forEach((a) => {
        if (a.code == code) {
          return a.title;
        }
      });
    },
    barcode(){
      var g = this;
      var teachers = this.teachers
      var printWindow = window.open("", "");
      printWindow.document.write(
        "<html><head dir='rtl'><title>entzam.com</title>"
      );

      //Print the Table CSS.
      printWindow.document.write(
        '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.6.2/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous"><style type = "text/css">'
      );
      printWindow.document.write(`
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
              text-align: right;
          }
          @media print {
              body{
                .col-6:nth-child(1),
                .col-6:nth-child(2),
                .col-6:nth-child(3),
                .col-6:nth-child(4){
                  position: relative;
                  top: 20px;
                }
              }
            }
            /*  ${g.size == 'col-4' ? 'body,html{ width: 21cm; height: 29.7cm; }' : 'body,html{ width: 100%; }'} */
            body,html{ width: 100%; }
          
          td,tr{
            padding: 1px !important;
          }
          /*.${g.size}{
            height: 14.2857143vh;
          }*/
          ${g.size == 'col-4' ? '.col-4{ height: 14.2857143vh; padding: 10px; /*width: 7cm; height: 5cm*/ }' : '.col-6{height: 14.2857143vh; padding: 10px;}'}
        `);
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      //Print the DIV contents i.e. the HTML Table.
      printWindow.document.write(`<body>
          <div class='container-fluid'>
            <div class='row'>`);
      var tabletext = "", i=0;
      teachers.forEach(function (teacher) {
        i++
        tabletext =
          tabletext +
          `
              <div class='${g.size}'>
            <div class='card card-body' style='height: 100%; padding: 5px;  border: 2px dashed #222'>
            <table class='table table-bordered'>
              <tbody>
                <tr>
                  <td>
                    <strong style='font-size: 12pt !important'>${
                      teacher.name
                    }</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src='https://barcode.tec-it.com/barcode.ashx?data=${teacher.number}&code=Code128'>
                  </td>
                </tr>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        `;
        if(i%(3*7) == 0 && g.size == "col-4"){
          tabletext = tabletext + `<div style="clear:both!important;"/></div>
<div style="page-break-after:always"></div> 
<div style="clear:both!important;"/> </div>`
        }
      });
      printWindow.document.write(
        `
            ` +
          tabletext 
      );
      setTimeout(() => {
      printWindow.print();
      }, 2000);
    }
  },
  data() {
    return {
      teachers: [],
      loading: true,
      size: "col-4"
    };
  },
  created() {
    var _g = this;
    axios
      .post(localStorage.getItem("api") + "/teachers/list", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.teachers = r.data.response;
          _g.loading = false;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
  },
};
</script>

<style>
</style>
